import { store } from "../../../../store/index";
import Api from "../../../../store/Services/Api";

/** 
 * javascript comment 
 * @Author: Ravi Sigdel 
 * @Date: 2021-04-13 11:40:00 
 * @Desc:  
 */
class ChequePrintService {

    storeChequePrint(data) {
        const url = 'api/cheque-print/store'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, data, headers);
    }

    getFilteredCheque(queryParams) {
        const url = `api/cheque-prints?page-index=${queryParams.pageIndex}`
        const params = {
            'today': queryParams.today,
            'month': queryParams.month,
            'year': queryParams.year,
            'from': queryParams.from_date,
            'to': queryParams.to_date,
            'searched': queryParams.searched,
            'page-reload': queryParams.page_reload,
            'offset': queryParams.offset
        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);

    }



    deleteChequePrint(id) {
        const url = 'api/cheque-print/' + id
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url, headers);
    }




}

export default new ChequePrintService();