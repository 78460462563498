<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'chequeSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content bg-white border0">
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">Cheque Slip</h5>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" @click="print(detail.id)" title="Print Slip"></i>
                </div>
                <div class="bill-head-icon mr-1" @click="closeSlipModal()">
                  <i class="fas fa-times" title="Close Slip"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="bill-container">
            <div class="cheque-container">
              <div class="cheque-content ht-200" style="height:200px;">
                <div class="cheque-date">
                  <span>{{detail.cheque_breaked_date[2] }}</span>
                  <span>{{detail.cheque_breaked_date[1] }}</span>
                  <span>{{detail.cheque_breaked_date[0] }}</span>
                </div>
                <div class="cheque-bank-name no-print">{{ detail.bank_name }}</div>
                <div class="cheque-name">{{ detail.cheque_name}}</div>
                <div class="cheque-amount-in-word">{{toWord(detail.amount)}} only</div>
                <div class="cheque-amount">{{ detail.amount }}/-</div>
                <div class="cheque-cheque-no no-print">{{ detail.cheque_no }}</div>
              </div>
              <img src="/assets/img/cheque_bg.png" alt class="no-print" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      detail: [],
    };
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("cheque", ["dataSets"]),
  },
  methods: {
    closeSlipModal() {
      this.$store.dispatch("modalClose");
    },
    print(id) {
      this.$emit("printSlip", id);
    },
  },
  watch: {
    dataId(value) {
      this.detail = this.dataSets.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>
<style>
@media print {
  @page {
    size: auto;
    margin: 0;
  }
  .bill-container {
    display: flex;
    justify-content: center;
  }
  .cheque-container {
    width: 9.4in;
    height: auto;
  }
  .cheque-date,
  .cheque-name,
  .cheque-amount-in-word,
  .cheque-amount {
    font-size: 22px;
  }
  .cheque-date {
    top: 45px;
    left: 650px;
    letter-spacing: 17.5px;
    right: 0;
  }
  .cheque-name {
    top: 100px;
    left: 175px;
  }
  .cheque-amount-in-word {
    top: 130px;
    left: 95px;
  }
  .cheque-amount {
    top: 150px;
    left: 655px;
  }
}
</style>