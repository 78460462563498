<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newCheque'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Cheque</h5>
            <a class="close" @click="$store.dispatch('modalClose', 'newCheque')" title="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div>
            <form method="post" class="bg-theam-secondary" enctype="multipart/form-data">
              <div class="modal-body row">
                <div class="col-md-6 pd-r-7-5">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="group">
                        <label class="group-label">Basic Information</label>
                        <div class="group-attribute-container">
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Issue Date *</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Item Name"
                                required
                                v-model="formData.issue_date"
                              />
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Bank Name"
                                required
                                v-model="formData.bank_name"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 mr-0 pr-0">Cheque No *</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Cheque No"
                                required
                                v-model="formData.cheque_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pd-l-7-5">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="group">
                        <label class="group-label">Cheque Detail *</label>
                        <div class="group-attribute-container">
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Cheque Date *</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="date"
                                step="any"
                                placeholder="Cheque Name"
                                class="form-control"
                                required
                                v-model="formData.cheque_date"
                              />
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Cheque Name *</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="text"
                                step="any"
                                placeholder="Cheque Name"
                                class="form-control"
                                required
                                v-model="formData.cheque_name"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 mr-0 pr-0">Cheque Amount *</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="number"
                                step="any"
                                placeholder="0.00"
                                class="form-control"
                                min="0"
                                required
                                v-model="formData.amount"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="submitData()"
                    :disabled="submitting"
                  >{{ submitting ? 'Submitting....' : 'Submit'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
export default {
  components: {},
  data() {
    return {
      formData: {
        issue_date: null,
        cheque_date: null,
        cheque_name: "",
        bank_name: "",
        amount: 0,
        cheque_no: "",
      },
      submitting: false,
    };
  },
  computed: {
    ...mapGetters([
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
      "authData",
      "today",
    ]),
    ...mapGetters("items", ["itemLists", "itemElements"]),
  },
  mounted() {
    this.formData.issue_date = this.today;
    this.formData.cheque_date = this.today;
  },
  methods: {
    submitData() {
      if (this.formValidate()) {
        this.submitting = true;
        Services.storeChequePrint(this.formData)
          .then((response) => {
            this.reset();
            this.setNotification(response.data.success_message);
            this.$emit("fetchCheques");
          })
          .catch((error) => {
            this.error = true;
            console.log(error);
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    formValidate() {
      if (this.formData.amount < 1) {
        this.setNotification("Amount cannot be less than 1.");
        return false;
      }
      return true;
    },
    reset() {
      this.formData.cheque_name = "";
      this.formData.bank_name = "";
      this.formData.cheque_no = "";
      this.formData.amount = "";
      this.formData.cheque_date = this.today;
      this.formData.issue_date = this.today;
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },
};
</script>
<style>
.unique-message {
  color: #db4437;
}
</style>
