<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Cheque</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Manage Cheque</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Cheque</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          v-if="checkSinglePermission('create_cheque_print')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          tag="button"
          @click="$store.dispatch('modalWithMode', { id: 'newCheque', mode: 'create' })"
        >New Cheque</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select class="mr-2 mg-t-5" v-model="filterParams.pageIndex" @change="selectTotalData">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <SearchDayMonthComponent @clicked="monthDayClicked"></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              v-model="filterParams.from_date"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              placeholder="To"
              class="mr-1 filter-input mg-t-5"
              v-model="filterParams.to_date"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            class="float-right"
            placeholder="Search..."
            v-model="filterParams.searched"
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-20p">Bank Name</th>
              <th class="wd-15p">Cheque No.</th>
              <th class="wd-25p">Cheque Name</th>
              <th class="wd-15p">Amount</th>
              <th class="wd-10p text-right table-end-item no-print">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <tr v-for="(cheque, index) in dataSets" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}</th>
              <td>{{ cheque.cheque_date }}</td>
              <td>{{ cheque.bank_name }}</td>
              <td>{{ cheque.cheque_no }}</td>
              <td>{{ cheque.cheque_name }}</td>
              <td>{{ cheque.amount }}</td>
              <td class="text-right table-end-item no-print">
                <a href="javascript:;" class="mr-3" title="View Slip">
                  <i class="fas fa-eye tx-success" @click="view('chequeSlip', cheque.id)"></i>
                </a>
                <span>
                  <a href="javascript:;" title="Cancel Cheque" @click="cancelCheque(cheque.id)">
                    <i class="fa fa-times-circle tx-danger"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align:center;">{{ errorMessage }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <ChequeSlip @printSlip="printSlip" />
    <Create @fetchCheques="getFilteredDataWithoutDates()" />
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import Services from "./Services/Service";
import Create from "./create";
import ChequeSlip from "./view";
import Paginate from "vuejs-paginate";
export default {
  components: {
    SearchDayMonthComponent,
    ChequeSlip,
    Paginate,
    Create,
  },
  data() {
    return {
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      errorMessage: "",
      page: 1,
      filterParams: {
        pageIndex: 10,
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
      "authData",
      "today",
    ]),
    ...mapGetters("cheque", ["dataSets", "itemSearchLoading"]),
  },
  beforeMount() {
    // get Data
    this.getFilteredData();
    this.pageReload = true;
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.page = 1;
        this.pageSerialNo = 1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
      }
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.$store.commit("cheque/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),

    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getFilteredCheque(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("cheque/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.$store.commit("cheque/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("cheque/setItemSearchLoading", true);
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("cheque/setItemSearchLoading", false);
        });
    },
    cancelCheque(id) {
      this.$swal({
        title: "Do you really want to cancel this Cheque Print ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the cheque with remarks
          return Services.deleteChequePrint(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
                this.dataSets.forEach((el, index) => {
                  if (el.id == id) {
                    this.dataSets.splice(index, 1);
                  }
                });
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print(id);
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
  },
};
</script>